import $http from '../utils/http';

// 登录接口
const authLogin = params => {
    const data = params?.data ?? params;
    const config = params?.config ?? null;
    return $http.post(
       `${$http.operation}rest/authLogin/login`,
        data,
        config
    );
};

// 获取年级
const getGrades = params => {
    const data = params?.data ?? params;
    const config = params?.config ?? null;
    return $http.post(
        $http.operation + 'grade/getGrades',
        data,
        config
    );
};


// 获取学科
const getSubject = params => {
    const data = params?.data ?? params;
    const config = params?.config ?? null;
    return $http.post(
        $http.operation + 'subject/getCourses',
        data,
        config
    );
};

// 获取码表枚举
const getEnum = params => {
    const data = params?.data ?? params;
    const config = params?.config ?? null;
    return $http.post(
        $http.api + 'plan/allEnums',
        data,
        config
    );
};

// 分页获取方案模板
const getProgramme = params => {
    const data = params?.data ?? params;
    const config = params?.config ?? null;
    return $http.post(
        $http.api + 'plan/page',
        data,
        config
    );
};

// 保存方案模板
const saveProgramme = params => {
    const data = params?.data ?? params;
    const config = params?.config ?? null;
    return $http.post(
        $http.api + 'plan/save',
        data,
        config
    );
};
// 发布方案模板
const publishProgramme = params => {
    const data = params?.data ?? params;
    const config = params?.config ?? null;
    return $http.post(
        $http.api + 'plan/publish',
        data,
        config
    );
};
// 删除方案模板
const deleteProgramme = params => {
    const data = params?.data ?? params;
    const config = params?.config ?? null;
    return $http.post(
        $http.api + 'plan/delete',
        data,
        config
    );
};
// 克隆方案模板
const copyProgramme = params => {
    const data = params?.data ?? params;
    const config = params?.config ?? null;
    return $http.post(
        $http.api + 'plan/clone',
        data,
        config
    );
};

// 获取方案模板
const getProgrammeDetails = params => {
    const data = params?.data ?? params;
    const config = params?.config ?? null;
    return $http.post(
        $http.api + 'plan/get',
        data,
        config
    );
};

// 获取出入参数据
const getMetaData = params => {
    const data = params?.data ?? params;
    const config = params?.config ?? null;
    return $http.post(
        $http.api + 'plan/metaData',
        data,
        config
    );
};


export default {
    authLogin,
    getGrades,
    getSubject,
    getEnum,
    getProgramme,
    saveProgramme,
    copyProgramme,
    publishProgramme,
    deleteProgramme,
    getProgrammeDetails,
    getMetaData
}